<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:45:50
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .news-info {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      background-color: white;
      text-align: center;
      padding-top: 55px;
      padding-bottom: 80px;
      .msg-title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
      }
      .msg-detail {
        margin-top: 56px;
        color: #666666;
        font-size: 18px;
        line-height: 40px;
      }
      .msg-detail-title {
        color: #999999;
        font-weight: bold;
      }
      img {
        width: 166px;
        height: 166px;
        margin-top: 51px;
        display: inline;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="news-info">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
        :curPage="curPageName"
      ></questionnaire-header>
      <div class="content-info">
        <p class="msg-title"> {{ noticeData.notice_title }}</p>
        <div class="msg-detail">
          <p class="msg-detail-title">{{ noticeData.update_time }}</p>
          <p>{{ noticeData.notice_content }}</p>
        </div>
        <!-- <img src="@assets/images/ico_wvpublic.png" /> -->
      </div>    
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";
import { fetchnoticeDetail } from "@/api/user.js"

export default {
  name: "SurveyNews",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "调查中心>",
      curPageName: "最新动态",
      noticeId: undefined,
      noticeData: {},
    };
  },
  created() {
    this.noticeId = this.$route.query.noticeId;
    this.initData()
  },
  methods: {
    initData() {
      this.getNoticeDetail()
    },
    /// 获取公告消息
    async getNoticeDetail() {
      try {
        let result = await fetchnoticeDetail({
          member_id: this.userId,
          token: this.userToken, 
          notice_id: this.noticeId
        });  
        this.noticeData = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
  },

};
</script>
